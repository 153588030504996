import React from "react";
import './About.css'

const About = () => {
  return (
    <>
    <div className="about-page">
      <h1 className="about-header">About Me!</h1>
      <div className="about-writing">
        <p>
        Hi, my name is Jordan Smith and I am a 35-year-old front-end software engineer. Before entering the technology industry, I served as a deputy sheriff, where I developed important skills in problem solving, teamwork, and communication. These skills have greatly benefited me in my current role as a software engineer.
        </p>
        <p>
        I received my education at the Turing School of Software & Design, where I honed my skills in technologies such as JavaScript, HTML, CSS, ReactJS, TDD, and Cypress. My strong background in Test-Driven Development ensures that my code is of the highest quality and thoroughly tested.
        </p>
        <p>
        As a front-end software engineer, I work with a team of developers, designers, and project managers to bring clients' visions to life. My approach to software development emphasizes creating clean, maintainable, and scalable code. My experience in problem solving and teamwork 
        from my time as a deputy sheriff has allowed me to effectively collaborate with my team and deliver outstanding results for my clients.
        </p>
        <p>
        In my free time, I enjoy spending time with my family, hiking, reading, playing the guitar, and exploring new technologies. I am passionate about delivering exceptional solutions for my clients and staying up-to-date with the latest industry advancements.
        </p>
        <p>
        To summarize, my experience as a deputy sheriff and as a front-end software engineer has equipped me with valuable skills in problem solving, teamwork, communication, and technology. I am dedicated to delivering high-quality results and constantly improving my skills. Let's discuss how I can bring my expertise to your project.
        </p>
      </div>
    </div>
    </>
  );
};

export default About;