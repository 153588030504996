import React from "react";
import './Home.css'

const Home = () => {
  return (
    <section className="home-page">
      <div className="name-container">
        <h1 className="home-name">Jordan Smith</h1>
        <p className="home-occupation">Software Developer</p>
        <p className="built">Built with React</p>
      </div>
    </section>
  );
};

export default Home;