import React from "react";
import './Portfolio.css'
import Project1 from "../assets/travel-tracker.mp4"
import Project2 from "../assets/Fitlit.mp4"


const Portfolio = () => {
  return (
    <>
    <div className="portfolio-page">
      <h1 className="portfolio-header">Portfolio</h1>
    <section className="projects">
      <div className="container1">
        <p className="project-descrip">Built with JavaScript, CSS, HTML and utilized API calls</p>
        <video className="project1-video-container" autoPlay loop muted>
          <source src={Project1} type="video/mp4"/>
        </video>
        <p className="project1-para">
        I created a travel tracking application that allows a user to see the information on their past, current and pending trips.I utilize a login page with username and passwords that are unique to the user. I used a local server and incorporated fetch request to GET and POST data. Error handling was added to both fetch GET and POST requests to catch errors that may occur from either request. Features added allows users to see all data displayed in a visually pleasing manner. I utilized dayjs to format and standardize dates that are used throughout to conform dates. The user can search for trips using inputs and text fields to search for a sepcific a vacation. After the user submits all provided fields and they click submit, a photo of the specified placed appears with the amount of lodging cost and flight cost. It will also show the estimated cost for that specific trip. The user then has the option to book that trip or go back and search for a new trip. I added a helper function file to house functions that are use mulitiple times throughout the project to keep my code DRY.
        </p>
      </div>
      <div className="container2">
      <p className="project-descrip">Built with JavaScript, CSS, HTML and utilized API calls</p>
      <video className="project1-video-container" autoPlay loop muted>
        <source src={Project2} type="video/mp4"/>
      </video>
      <p className="project2-para">
      This was a collabrative project and we created a fitness tracking application that allows a user to see the information on their sleep, hydration and activity. We used a local server and incorporated fetch request to GET and POST data. Error handling was added to both fetch GET and POST requests to catch errors that may occur from either request. Features added allows users to see all data displayed in a visually pleasing manner using charts from chart.js. We utilized dayjs to format and standardize dates. The user can add new data for current day. After the user submits data the charts will update and populate with latest data. We utilized recursion to construct a weeks worth of data if there are gaps in the datas' date. We added a helper function file to house functions that are use mulitiple times throughout the project to keep our code DRY. The application also allows the user to take advantage of a calendar to manipulte dates to see a wide variety of information from different dates. Users can view their personal information inside of an information section.
      </p>
      </div>
    </section>
    </div>
    </>
  );
};

export default Portfolio;